import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import COMPONENTS from '../../../../../bi/constants/components';
import {
  AIRLINEORDERRATE,
  AIRLINE_CHANGE_RATE_FOR_SMARTAGENT,
} from '../../../../../constants/rateTypes';
import {
  FUNDSFIELDS,
  FUNDSFIELDSTYPES,
  FUNDSLABELS,
  DIALOGSTITLES,
  TRIPSLABELS,
  FIELDS,
} from '../../../../../bi/constants/trips';
import FLYCLASS, { VALUE_PROPERTY } from '../../../../../bi/constants/airline';
import AIRPROVIDERS from '../../../../../bi/constants/arline';
import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import Input from '../../../../../components/input';
import InputTime from '../../../../../components/InputTime';
import AjaxButton from '../../../../../components/ajaxButton';
import EmployeeSuggest from '../../../../../components/employee';
import TPDetails from '../../../../../components/tpDetails';
import Checkbox from '../../../../../components/checkbox';
import NoResult from '../../../../../components/NoResult';
import ProviderInfoSelect from '../components/ProviderInfoSelect';
import { getPassportType, getPassportDueDate } from '../../../../../bi/utils/passport';
import { isShowTaxes, isShowTaxesClue } from '../../../../../bi/utils/air';

import renderFundsForm from '../../form';

import Price from '../components/Price';
import Vat from '../components/Vat';

import Segments from './Segments';
import DeletePartDialog from './DeletePartDialog';

import styles from '../../../styles/form.module.scss';

const GET_INFO_ENTER_FIELDS = {
  TICKET_NUMBER: 'Введите номер билета',
  TICKET_NUMBER_EMPLOYEE_PASSPORT: 'Дожен быть заполнен сотрудник, паспорт и номер билета',
  TICKET_PNR: 'Дожен быть заполнен PNR',
};

const LABELS_GET_INFO = {
  [AIRPROVIDERS.AMADEUS.TYPE]: GET_INFO_ENTER_FIELDS.TICKET_NUMBER,
  [AIRPROVIDERS.SSEVEN.TYPE]: GET_INFO_ENTER_FIELDS.TICKET_NUMBER,
};

const GET_INFO_ERRORS = {
  ERROR_NUMBER: 'Не удалось загрузить информацию, неверный номер билета',
  ERROR_MANY_FIELDS: 'Не удалось загрузить информацию, PNR и фамилия не совпадают',
  ERROR_PNR: 'Не удалось загрузить информацию, PNR не совпадает',
};

const PNR_LABEL = {
  PNR: 'Код брони GDS',
  PNR_LOCATOR: 'Код Брони Авиакомпании',
  PNR_PROVIDER: 'Наименование GDS',
};

const LABELS = {
  DURATION: 'Время полета (часы)',
  USER_FEE_FOR_ORDER: 'У пользователя есть сбор за выписку равный',
  USER_FEE_FOR_CHANGE: 'У пользователя есть сбор за изменение равный',
  RUB: 'рублям',
  SELECT_OFFICE_ID: 'Выберите OfficeID провайдера',
  SELECT_SALE_POINT_ID: 'Выберите ППР провайдера',
  OFFICE_ID: 'Office Id',
  SALE_POINT: 'ППР',
};

const Item = ({
  key,
  isEditing,
  specialBillAccount,
  ticket,
  employees,
  providerNameOptions,
  handleChangeInput,
  handlerRemoveEmployee,
  onSelectEmployee,
  onChangeEmployee,
  handleChangeVatDetails,
  handlerAddPart,
  handlerDeletePart,
  handleBlurInput,
  validation,
  airService,
  tpDetails,
  onEmployeeFocus,
  changeRate,
  surcharges,
  changeVat,
  onChangeFinancesEnabled,
  onChangeSurcharges,
  airline,
  returnFunds,
  onChangeReturnFunds,
  disablePickEployee,
  handleGetInfo,
  waitingResponse,
  handleChangeFlyClass,
  getInfoError,
  handleSetInfoError,
  saveOperationAirline,
}) => {
  const routeHtml = ticket.Routes.map((route, index) => {
    const addHtml = handlerAddPart ? (
      <div className={ styles.icon } onClick={ () => handlerAddPart({ type: 'route', ticketInd: key }) }>
        <i className='material-icons' title='Добавить маршрут'>add</i>
      </div>
        ) : null;
    const removeHtml = ticket.Routes.length > 1 && handlerDeletePart ? (
      <DeletePartDialog
        title={ DIALOGSTITLES.CONFIRM_DELETE_ROUTE }
        handlerDeletePart={ () => handlerDeletePart({ type: 'route', ticketInd: key, routeInd: index }) }
      />
        ) : null;

    const renderError = value => (
        value.length
          ? <span className={ `error-msg ${styles['error-block']}` }>{ value }</span>
          : null
      );

    const routeValidation = validation.Routes[index];
    const segmentsHtml = route.Segments.map((segment, i) => (
      <Segments
        key={ i }
        segment={ segment }
        index={ i }
        handleChangeInput={ handleChangeInput }
        handlerAddPart={ handlerAddPart }
        handlerDeletePart={ handlerDeletePart }
        handleBlurInput={ handleBlurInput }
        validation={ routeValidation.Segments[i] }
        segmentsCount={ route.Segments.length }
        ticketInd={ key }
        routeInd={ index }
        airService={ airService }
        saveOperationAirline={ saveOperationAirline }
      />
    ));

    return (
      <div className={ styles.row } key={ index }>
        <div className={ `${styles.row} ${styles.title}` }>
          <h5>Маршрут №{ (index + 1) }</h5>
          { addHtml }
          { removeHtml }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-4'] }>
            <InputTime
              field={ FIELDS.DURATION }
              value={ route.Duration }
              label={ LABELS.DURATION }
              onChange={ value => handleChangeInput(key, index, null, null, FIELDS.DURATION, value) }
            />
            { renderError(routeValidation.Duration) }
          </div>
          <div className={ styles['col-1-4'] }>
            <div className={ styles.select }>
              <div className={ styles.wrap }>
                <label>Маршрут (туда/обратно)</label>
                <select
                  value={ route.RouteIndex }
                  onChange={ e =>
                    handleChangeInput(key, index, null, e, 'RouteIndex', e.target.value)
                  }
                >
                  <option value='0'>Туда</option>
                  <option value='1'>Обратно</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          { segmentsHtml }
        </div>
      </div>
    );
  });

  const selectedEmployee = ticket.Travellers ? ticket.Travellers : {};

  let selectHtml = null;
  if (ticket.Travellers && ticket.Travellers.Documents) {
    const passportHtml = ticket.Travellers ? ticket.Travellers.Documents.map((passport, index) => {
      const passportType = getPassportType(ticket.Travellers.Code, passport.Type);
      const passportDueDate = getPassportDueDate(passport.DueDate);
      const passportDueDateHtml = passportDueDate ? `до ${passportDueDate}` : '';

      return (
        <option
          key={ `${passport.Id}_${index}` }
          value={ passport.Id }
        >
          { `${passportType} -  ${passport.Number} ${passportDueDateHtml}` }
        </option>
      );
    }) : null;

    const passportValue = ticket.Passport ? (ticket.Passport.PassportId || '') : '';

    selectHtml = (
      <select
        value={ passportValue }
        onChange={ e =>
          handleChangeInput(key, null, null, e, 'Passport.PassportId', e.target.value)
        }
      >
        { passportHtml }
      </select>
    );
  } else {
    selectHtml = (<span className={ styles['no-valid'] }>У сотрудника нет паспорта</span>);
  }

  const validProviderNameHtml = validation.ProviderName ? (
    <span className={ `${styles['error-msg-company']} error-msg` }>{ validation.ProviderName }</span>
  ) : null;

  const selectProvideNameHtml = () => {
    const { ProviderName, ProviderInfo } = ticket;

    const filteredOptions = providerNameOptions.filter(({ value }) => (
      value === AIRPROVIDERS.SSEVEN.TYPE
      || value === AIRPROVIDERS.AEROFLOT.TYPE
      || value === AIRPROVIDERS.AVIACENTR.TYPE
    ));
    const options = specialBillAccount ? filteredOptions : providerNameOptions;

    const handelSelectProviderName = (e) => {
      if (ProviderName === AIRPROVIDERS.AMADEUS.TYPE && ProviderInfo) {
        handleChangeInput(key, null, null, null, FIELDS.PROVIDER_INFO.OFFICE_ID, null);
      }

      handleChangeInput(key, null, null, e, FIELDS.PROVIDERNAME, e.target.value);
    };

    return (
      <select
        value={ ProviderName }
        onChange={ handelSelectProviderName }
      >
        <option key={ '' } value={ '' } disabled>Выберите провайдера</option>
        { options.map(({ value, label }, providerKey) => (
          <option key={ `${providerKey}_name` } value={ value }>{ label }</option>
      )) }
      </select>
    );
  };

  const selectProviderInfoHtml = (
    type,
    labelSelect,
    field,
    infoType,
    airProviderSelectorFunc,
    validationField,
    selectLabel,
  ) => {
    const { ProviderName, ProviderInfo } = ticket;

    const classNameValid = validationField ? styles.no_valid_officeId : '';

    if (ProviderName !== type || !ProviderInfo) {
      return null;
    }

    return (
      <ProviderInfoSelect
        providerInfo={ ProviderInfo }
        providerName={ ProviderName }
        labelSelect={ labelSelect }
        field={ field }
        k={ key }
        infoType={ infoType }
        airProviderSelectorFunc={ airProviderSelectorFunc }
        selectLabel={ selectLabel }
        handleChangeInput={ handleChangeInput }
        validationField={ validationField }
        classNameValid={ classNameValid }
      />
    );
  };

  const selectProvideOfficeIdHtml = () => selectProviderInfoHtml(
    AIRPROVIDERS.AMADEUS.TYPE,
    LABELS.OFFICE_ID,
    FIELDS.PROVIDER_INFO.OFFICE_ID,
    VALUE_PROPERTY.OFFICE_ID,
    airService.getAirProviderOfficeIdForSelect,
    validation.ProviderInfo.OfficeId,
    LABELS.SELECT_OFFICE_ID
  );

  const selectProviderSalePointHtml = () => selectProviderInfoHtml(
    AIRPROVIDERS.MIXVEL.TYPE,
    LABELS.SALE_POINT,
    FIELDS.PROVIDER_INFO.SALE_POINT,
    VALUE_PROPERTY.SALE_POINT,
    airService.getAirProviderSalePointForSelect,
    validation.ProviderInfo.SalePoint,
    LABELS.SELECT_SALE_POINT_ID
  );

  const renderChangeRateAlert = () => {
    if (!changeRate.airline) return null;

    const { airline: { Order, Change } } = changeRate;

    const rubOrder =
      AIRLINEORDERRATE[Order] !== AIRLINEORDERRATE.Percent4
      && AIRLINEORDERRATE[Order] !== AIRLINEORDERRATE.Percent5
      && LABELS.RUB;

    return (
      <div className={ `${styles.row} ${styles['rate-alert']}` }>
        <div className={ `${styles.row}` }>
          { LABELS.USER_FEE_FOR_ORDER } { AIRLINEORDERRATE[Order] } { rubOrder }.
        </div>
        <div className={ `${styles.row}` }>
          { LABELS.USER_FEE_FOR_CHANGE } { AIRLINE_CHANGE_RATE_FOR_SMARTAGENT[Change] } { LABELS.RUB }.
        </div>
      </div>
    );
  };

  const renderVat = isFundsForm => (
    <Vat
      isFundsForm={ isFundsForm }
      isEditing={ isEditing }
      keyItem={ key }
      airline={ airline }
      ticket={ ticket }
      handleChangeVatDetails={ handleChangeVatDetails }
    />
  );

  const renderFinanceOperations = () => {
    if (!isEditing) return null;

    const changeVatFormHtml = changeVat && changeVat.Enabled ? renderVat(true) : null;
    const returnFundsFormHtml = returnFunds.Enabled &&
      renderFundsForm(returnFunds, onChangeReturnFunds, FUNDSFIELDSTYPES.RETURNFUNDS);

    const surchargesFormHtml = surcharges.Enabled &&
      renderFundsForm(surcharges, onChangeSurcharges, FUNDSFIELDSTYPES.SURCHARGES, SERVICETYPE.AIR);

    const changeVatHtml = (surcharges.Enabled || returnFundsFormHtml) && (
      <div className={ styles['col-1-3'] }>
        <Checkbox
          field={ FUNDSFIELDS.CHANGEVAT }
          label={ FUNDSLABELS.CHANGEVAT }
          value={ changeVat.Enabled }
          onChange={ onChangeFinancesEnabled }
        />
        { changeVatFormHtml }
      </div>
    );

    const surchargesHtml = (
      <div className={ styles['col-1-3'] }>
        <Checkbox
          field={ FUNDSFIELDS.SURCHARGES }
          label={ FUNDSLABELS.SURCHARGESAIR }
          value={ surcharges.Enabled }
          onChange={ onChangeFinancesEnabled }
        />
        { surchargesFormHtml }
      </div>
    );

    const returnFundshtml = (
      <div className={ styles['col-1-3'] }>
        <Checkbox
          field={ FUNDSFIELDS.RETURNFUNDS }
          label={ FUNDSLABELS.RETURNFUNDS }
          value={ returnFunds.Enabled }
          onChange={ onChangeFinancesEnabled }
        />
        { returnFundsFormHtml }
      </div>
    );

    return (
      <div className={ `${styles.row} ${styles.panel}` }>
        <h4>Финансовые операции</h4>
        <div className={ styles.row }>
          { surchargesHtml }
          { returnFundshtml }
          { changeVatHtml }
        </div>
      </div>
    );
  };

  const renderPrices = () => {
    const showTaxes = isShowTaxes(ticket);
    const showTaxesClue = isShowTaxesClue(ticket);

    return (
      <Price
        showTaxes={ showTaxes }
        showTaxesClue={ showTaxesClue }
        isEditing={ isEditing }
        priceDetails={ ticket.PriceDetails }
        onChangeInput={ (...props) => handleChangeInput(key, null, null, ...props) }
        onBlurInput={ (e, f) => handleBlurInput(key, null, null, f) }
        validation={ validation }
      />
    );
  };

  const selectOptionsHtml = Object.keys(FLYCLASS).map(flyClassKey =>
    <option key={ flyClassKey } value={ flyClassKey }>{ FLYCLASS[flyClassKey] }</option>
  );

  const renderGetInfo = () => {
    const { ProviderName, Num, PNR, Travellers, ProviderInfo: { OfficeId, SalePoint } } = ticket;
    const documents = (Travellers && typeof (Travellers) !== 'number' && Travellers.Documents[0]) || null;
    const Lastname = documents && documents.LastName;
    const Surname = documents && documents.Surname;

    if (ProviderName !== AIRPROVIDERS.AMADEUS.TYPE &&
        ProviderName !== AIRPROVIDERS.SSEVEN.TYPE &&
        ProviderName !== AIRPROVIDERS.AEROFLOT.TYPE &&
        ProviderName !== AIRPROVIDERS.TAIS.TYPE &&
        ProviderName !== AIRPROVIDERS.MIXVEL.TYPE) {
      return null;
    }

    const disabled = !Num;

    const tooltip = disabled && (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
        { LABELS_GET_INFO[ProviderName] }
      </Tooltip>
    );

    return (
      <div className={ styles.button_info }>
        <div className='sw-tooltip-wrapper'>
          <AjaxButton
            disabled={ disabled }
            label={ TRIPSLABELS.GET_DATA }
            loading={ waitingResponse }
            onClick={ () => handleGetInfo(ProviderName, Num, PNR, Surname, Lastname, OfficeId, SalePoint) }
          />
          { tooltip }
        </div>
      </div>
    );
  };

  const renderGetInfoError = () => {
    const { ProviderName } = ticket;

    if (getInfoError.length === 0) return null;

    switch (ProviderName) {
      case AIRPROVIDERS.AMADEUS.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError('') }
            message={ GET_INFO_ERRORS.ERROR_NUMBER }
          />
        );
      }
      case AIRPROVIDERS.SSEVEN.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError('') }
            message={ GET_INFO_ERRORS.ERROR_NUMBER }
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={ styles.row } key={ key }>
      <div className={ `${styles.row} ${styles.title}` }>
        <h5>Билет №{ (key + 1) }</h5>
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <EmployeeSuggest
            items={ employees }
            selected={ selectedEmployee }
            placeholder='Сотрудники'
            onRemove={ () => handlerRemoveEmployee({ ticketInd: key }) }
            onSelect={ employee => onSelectEmployee({ ticketInd: key, employee }) }
            valid={ validation.Travellers }
            onFocus={ () => onEmployeeFocus({ ticketInd: key }) }
            onChange={ onChangeEmployee }
            disablePick={ disablePickEployee }
          />
          <TPDetails
            details={ tpDetails }
          />
        </div>
        <div className={ styles['col-1-3'] }>
          <div className={ styles.select }>
            <div className={ styles.wrap }>
              <label>Паспорт</label>
              { selectHtml }
            </div>
          </div>
        </div>
        <div className={ styles['col-1-3'] }>
          <div className={ styles.select }>
            <div className={ styles.wrap }>
              <label>Класс перелета</label>
              <select
                value={ ticket.Class }
                onChange={ e => handleChangeFlyClass(key, null, null, e, 'Class', e.target.value) }
              >
                { selectOptionsHtml }
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ `${styles['col-1-5']} ${styles.number}` }>
          <Input
            field='Num'
            value={ ticket.Num }
            label='Номер билета'
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
            onBlur={ (e, f) => handleBlurInput(key, null, null, f) }
            valid={ validation.Num }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field='PNR'
            value={ ticket.PNR }
            label={ PNR_LABEL.PNR }
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
            valid={ validation.PNR }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field='PNRProvider'
            value={ ticket.PNRProvider }
            label={ PNR_LABEL.PNR_PROVIDER }
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field='PNRLocator'
            value={ ticket.PNRLocator }
            label={ PNR_LABEL.PNR_LOCATOR }
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <div className={ styles.select }>
            <label>Провайдер</label>
            <div className={ `${styles.wrap} ${validation.ProviderName ? styles['no-valid'] : ''}` }>
              { selectProvideNameHtml() }
              { validProviderNameHtml }
              { selectProvideOfficeIdHtml() }
              { selectProviderSalePointHtml() }
              { renderGetInfo() }
              { renderGetInfoError() }
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.row }>
        { routeHtml }
      </div>
      { renderChangeRateAlert() }
      { renderFinanceOperations() }
      { renderPrices() }
      { renderVat() }
    </div>
  );
};

Item.propTypes = {
  key: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  specialBillAccount: PropTypes.object.isRequired,
  ticket: PropTypes.object.isRequired,
  employees: PropTypes.array,
  providerNameOptions: PropTypes.array.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  handlerDeletePart: PropTypes.func.isRequired,
  handlerRemoveEmployee: PropTypes.func.isRequired,
  onSelectEmployee: PropTypes.func.isRequired,
  onChangeEmployee: PropTypes.func.isRequired,
  handleChangeVatDetails: PropTypes.func.isRequired,
  handlerAddPart: PropTypes.func.isRequired,
  handleBlurInput: PropTypes.func.isRequired,
  validation: PropTypes.array.isRequired,
  airService: PropTypes.object.isRequired,
  tpDetails: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  onEmployeeFocus: PropTypes.func.isRequired,
  changeRate: PropTypes.object,
  surcharges: PropTypes.object.isRequired,
  changeVat: PropTypes.object.isRequired,
  returnFunds: PropTypes.object,
  onChangeFinancesEnabled: PropTypes.func.isRequired,
  onChangeReturnFunds: PropTypes.func,
  onChangeSurcharges: PropTypes.func.isRequired,
  airline: PropTypes.object.isRequired,
  disablePickEployee: PropTypes.bool,
  handleGetInfo: PropTypes.func.isRequired,
  waitingResponse: PropTypes.bool.isRequired,
  handleChangeFlyClass: PropTypes.func.isRequired,
  saveOperationAirline: PropTypes.func.isRequired,
  getInfoError: PropTypes.string,
  handleSetInfoError: PropTypes.func,
};

Item.defaultProps = {
  employees: null,
  changeRate: {},
  isEditing: false,
  disablePickEployee: false,
  returnFunds: {},
  onChangeReturnFunds: () => {},
  getInfoError: '',
  handleSetInfoError: () => {},
};

export default Item;
