import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'sw-ui';

import styles from '../../../styles/form.module.scss';
import { TRIPBUTTONS } from '../../../../../bi/constants/trips';

class DeletePartDialog extends Component {
  static propTypes = {
    handlerDeletePart: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShowDialog: false,
    };
  }

  handleToggleDialog = isShowDialog => () => this.setState({ isShowDialog });

  handlerDeletePart = () => {
    this.handleToggleDialog(false)();
    this.props.handlerDeletePart();
  };

  render() {
    const { isShowDialog } = this.state;
    const { title } = this.props;

    const dialogHtml = isShowDialog && (
      <Dialog onClick={ this.handleToggleDialog(false) }>
        <div className={ styles.dialog }>
          <b>{title}</b>
          <div className={ styles.buttons }>
            <button
              className={ styles.button }
              onClick={ this.handlerDeletePart }
            >
              {TRIPBUTTONS.CONFIRM_DIALOG}
            </button>
            <button
              className={ `${styles.button} ${styles.cancel}` }
              onClick={ this.handleToggleDialog(false) }
            >
              {TRIPBUTTONS.CANCEL_DIALOG}
            </button>
          </div>
        </div>
      </Dialog>
    );

    return (
      <div>
        <div className={ styles.icon } onClick={ this.handleToggleDialog(true) }>
          <i className='material-icons' title='Удалить билет'>clear</i>
        </div>
        { dialogHtml }
      </div>
    );
  }
}
export default DeletePartDialog;
