import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { DatePicker, COMPONENTS } from 'sw-ui';

import Input from '../../../../../components/input';
import InputTime from '../../../../../components/InputTime';

import { DIALOGSTITLES, FIELDS } from '../../../../../bi/constants/trips';

import DeletePartDialog from './DeletePartDialog';

import styles from '../../../styles/form.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

const LABELS = {
  CLASS: 'Класс',
  BAGGAGE: 'Номер багажа',
  AIRCRAFT_NAME: 'Самолет',
  ARRIVAL_TIME: 'Время прилета',
  ARRIVAL_DATE: 'Дата прилета',
  ARRIVAL_AIRPORT: 'Код, город, страна, аэропорт прилета',
  DEPARTURE_AIRPORT: 'Код, город, страна, аэропорт вылета',
  TERMINAL: 'Терминал',
  DEPARTURE_TIME: 'Время вылета',
  DEPARTURE_DATE: 'Дата вылета',
  FLIGHT_NUMBER: 'Номер рейса',
  OPERATING_AIRLINE: 'Фактический перевозчик',
  AIR_COMPANY: 'Авиакомпания',
  SEGMENT_NUMBER: 'Сегмент №',
};

class Segments extends Component {
  static propTypes = {
    segment: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    ticketInd: PropTypes.number.isRequired,
    routeInd: PropTypes.number.isRequired,
    segmentsCount: PropTypes.number.isRequired,
    handleChangeInput: PropTypes.func.isRequired,
    handlerAddPart: PropTypes.func.isRequired,
    handlerDeletePart: PropTypes.func.isRequired,
    handleBlurInput: PropTypes.func.isRequired,
    validation: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    saveOperationAirline: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.setStateFromProps(props.segment);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setStateFromProps(newProps.segment);
  }

  setStateFromProps = ({
    DepartureAirport = {},
    DepartureCity = '',
    DepartureCountry = '',
    ArrivalAirport = {},
    ArrivalCity = '',
    ArrivalCountry = '',
    MarketingAirline = {},
    OperatingAirline = {},
  }) => {
    const defaultFrom = DepartureAirport.Code && DepartureCity && DepartureCountry && DepartureAirport.Name ?
      `${DepartureAirport.Code} ${DepartureCity} ${DepartureCountry} ${DepartureAirport.Name}` : '';

    const defaultTo = ArrivalAirport.Code && ArrivalCity && ArrivalCountry && ArrivalAirport.Name ?
      `${ArrivalAirport.Code} ${ArrivalCity} ${ArrivalCountry} ${ArrivalAirport.Name}` : '';

    let defaultMarketingAirline = '';
    let defaultOperatingAirline = '';
    if (MarketingAirline.Code && MarketingAirline.Name) {
      defaultMarketingAirline = `${MarketingAirline.Code} ${MarketingAirline.Name}`;
    }
    if (OperatingAirline.Code && OperatingAirline.Name) {
      defaultOperatingAirline = `${OperatingAirline.Code} ${OperatingAirline.Name}`;
    }

    this.state = {
      from: {
        label: defaultFrom,
        selected: {},
        suggests: [],
      },
      to: {
        label: defaultTo,
        selected: {},
        suggests: [],
      },
      marketingAirline: {
        label: defaultMarketingAirline,
        selected: {},
        suggests: [],
      },
      operatingAirline: {
        label: defaultOperatingAirline,
        selected: {},
        suggests: [],
      },
    };
  };

  handleSelectSuggest = (field, cValue, value) => {
    const { handleChangeInput, handleBlurInput, index, ticketInd, routeInd } = this.props;

    const city = value.City;
    const country = value.Country;
    const name = value.Name;
    const code = value.Code;

    switch (field) {
      case 'from':
        handleChangeInput(ticketInd, routeInd, index, null, 'DepartureCity', city);
        handleChangeInput(ticketInd, routeInd, index, null, 'DepartureCountry', country);
        handleChangeInput(ticketInd, routeInd, index, null, 'DepartureAirport.Code', code);
        handleChangeInput(ticketInd, routeInd, index, null, 'DepartureAirport.Name', name);

        handleBlurInput(ticketInd, routeInd, index, 'DepartureAirport.Code');

        this.setState({
          from: {
            ...this.state.from,
            label: `${code} ${city} ${country} ${name}`,
          },
        });
        break;
      case 'to':
        handleChangeInput(ticketInd, routeInd, index, null, 'ArrivalCity', city);
        handleChangeInput(ticketInd, routeInd, index, null, 'ArrivalCountry', country);
        handleChangeInput(ticketInd, routeInd, index, null, 'ArrivalAirport.Code', code);
        handleChangeInput(ticketInd, routeInd, index, null, 'ArrivalAirport.Name', name);

        handleBlurInput(ticketInd, routeInd, index, 'ArrivalAirport.Code');

        this.setState({
          to: {
            ...this.state.to,
            label: `${code} ${city} ${country} ${name}`,
          },
        });
        break;
    }
  };

  handleChangeSuggest = (field, value) => {
    this.setState({
      [field]: {
        ...this.state[field],
        label: value,
      },
    }, () => {
      this.props.airService.autocomplete(this.state[field].label).then((res) => {
        let result = [];

        res.forEach((airport) => {
          result.push(airport);

          if (airport.ChildLocation) result = result.concat(airport.ChildLocation);
        });

        this.setState({
          [field]: {
            ...this.state[field],
            suggests: result,
          },
        });
      });
    });
  };

  handleSelectAirlineSuggest = (field, value, airline) => {
    const { handleChangeInput, handleBlurInput, index, ticketInd, routeInd, saveOperationAirline } = this.props;
    const { IATA } = value;

    const camelCasedAirline = `${airline.slice(0, 1).toUpperCase()}${airline.slice(1)}`;

    saveOperationAirline(IATA);
    handleBlurInput(0, null, null, 'Num', IATA);
    handleChangeInput(ticketInd, routeInd, index, null, `${camelCasedAirline}.Code`, IATA);
    handleChangeInput(ticketInd, routeInd, index, null, `${camelCasedAirline}.Name`, value.Name);
    handleBlurInput(ticketInd, routeInd, index, `${camelCasedAirline}.Code`);

    this.setState({
      [airline]: {
        ...this.state[airline],
        label: `${IATA} ${value.Name}`,
      },
    });

    // если мы меняем авиакомпанию, то нужно автоматически подставить и фактического перевозчика
    if (camelCasedAirline === 'MarketingAirline') {
      handleChangeInput(ticketInd, routeInd, index, null, 'OperatingAirline.Code', IATA);
      handleChangeInput(ticketInd, routeInd, index, null, 'OperatingAirline.Name', value.Name);
      handleBlurInput(ticketInd, routeInd, index, 'OperatingAirline.Code');

      this.setState({
        operatingAirline: {
          ...this.state.operatingAirline,
          label: `${IATA} ${value.Name}`,
        },
      });
    }
  };

  handleChangeAirlineSuggest = (value, airline) => {
    this.setState({
      [airline]: {
        ...this.state[airline],
        label: value,
      },
    }, () => {
      this.props.airService.autocompleteAirlines(this.state[airline].label).then((res) => {
        this.setState({
          [airline]: {
            ...this.state[airline],
            suggests: res,
          },
        });
      });
    });
  };

  render() {
    const { from, to, marketingAirline, operatingAirline } = this.state;

    const {
      segment,
      index,
      ticketInd,
      routeInd,
      segmentsCount,
      handleChangeInput,
      handlerAddPart,
      handlerDeletePart,
      handleBlurInput,
      validation,
    } = this.props;

    const addHtml = handlerAddPart ? (
      <div className={ styles.icon } onClick={ () => handlerAddPart({ type: 'segment', routeInd, ticketInd }) }>
        <i className='material-icons' title='Добавить сегмент'>add</i>
      </div>
    ) : null;
    const removeHtml = segmentsCount > 1 && handlerDeletePart ? (
      <DeletePartDialog
        title={ DIALOGSTITLES.CONFIRM_DELETE_SEGMENT }
        handlerDeletePart={ () =>
          handlerDeletePart({ type: 'segment', ticketInd, routeInd, segmentInd: index })
        }
      />
    ) : null;

    const validDepartureDate = !!validation.DepartureDate &&
      <span className={ styles['error-msg'] }>{ validation.DepartureDate }</span>;

    const validDepartureTime = !!validation.DepartureTime &&
      <span className={ styles['error-msg'] }>{ validation.DepartureTime }</span>;

    const validArrivalDate = !!validation.ArrivalDate &&
      <span className={ styles['error-msg'] }>{ validation.ArrivalDate }</span>;

    const validArrivalTime = !!validation.ArrivalTime &&
      <span className={ styles['error-msg'] }>{ validation.ArrivalTime }</span>;

    const validDepartureAuto = !!(validation.DepartureAirport.Code || validation.DepartureAirport.Name) &&
      <span className={ styles['error-msg'] }>{ validation.DepartureAirport.Code || validation.DepartureAirport.Name }</span>;

    const validArrivalAuto = !!validation.ArrivalAirport.Code &&
      <span className={ styles['error-msg'] }>{ validation.ArrivalAirport.Code }</span>;

    const validMarketingAirline = !!(validation.MarketingAirline.Code || validation.MarketingAirline.Name) &&
      <span className={ styles['error-msg'] }>{ validation.MarketingAirline.Code || validation.MarketingAirline.Name }</span>;

    const validOperatingAirline = !!(validation.OperatingAirline.Code || validation.OperatingAirline.Name) &&
      <span className={ styles['error-msg'] }>{ validation.OperatingAirline.Code || validation.OperatingAirline.Name }</span>;

    return (
      <div className={ styles.row } key={ index }>
        <div className={ `${styles.row} ${styles.title}` }>
          <h5>{LABELS.SEGMENT_NUMBER}{ (index + 1) }</h5>
          { addHtml }
          { removeHtml }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <div className={ `${styles['auto-input']} ${validation.MarketingAirline.Code ? styles['no-valid'] : ''}` }>
              <label>{ LABELS.AIR_COMPANY }</label>
              <Autocomplete
                menuStyle={ MENUSTYLE }
                value={ marketingAirline.label }
                items={ marketingAirline.suggests }
                getItemValue={ item => item.Name }
                onSelect={ (field, value) => this.handleSelectAirlineSuggest(field, value, FIELDS.MARKETING_AIRLINE) }
                onChange={ (event, value) => this.handleChangeAirlineSuggest(value, FIELDS.MARKETING_AIRLINE) }
                renderItem={ item =>
                  (<div className={ styles['autocomplete-item'] }>
                    { `${item.IATA} — ${item.Name}` }
                  </div>)
                }
              />
              { validMarketingAirline }
            </div>
          </div>
          <div className={ styles['col-1-3'] }>
            <div className={ `${styles['auto-input']} ${validation.OperatingAirline.Code ? styles['no-valid'] : ''}` }>
              <label>{ LABELS.OPERATING_AIRLINE }</label>
              <Autocomplete
                menuStyle={ MENUSTYLE }
                value={ operatingAirline.label }
                items={ operatingAirline.suggests }
                getItemValue={ item => item.Name }
                onSelect={ (field, value) => this.handleSelectAirlineSuggest(field, value, FIELDS.OPERATING_AIRLINE) }
                onChange={ (event, value) => this.handleChangeAirlineSuggest(value, FIELDS.OPERATING_AIRLINE) }
                renderItem={ item =>
                  (<div className={ styles['autocomplete-item'] }>
                    { `${item.IATA} — ${item.Name}` }
                  </div>)
                }
              />
              { validOperatingAirline }
            </div>
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.FLIGHT_NUMBER }
              value={ segment.FlightNumber }
              label={ LABELS.FLIGHT_NUMBER }
              onChange={ (...props) => handleChangeInput(ticketInd, routeInd, index, ...props) }
              onBlur={ (e, f) => handleBlurInput(ticketInd, routeInd, index, f) }
              valid={ validation.FlightNumber }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <div className={ `${styles['auto-input']} ${validation.DepartureAirport.Code ? styles['no-valid'] : ''}` }>
              <label>{ LABELS.DEPARTURE_AIRPORT }</label>
              <Autocomplete
                menuStyle={ MENUSTYLE }
                value={ from.label }
                items={ from.suggests }
                getItemValue={ item => item.Code }
                onSelect={ (cValue, value) => this.handleSelectSuggest(FIELDS.FROM, cValue, value) }
                onChange={ (event, value) => {
                  this.handleChangeSuggest(FIELDS.FROM, value);
                } }
                renderItem={ item =>
                  (<div className={ styles['autocomplete-item'] }>
                    { item.Code } -&nbsp;
                    { item.City }&nbsp;
                    { item.Country }&nbsp;
                    { item.Name }
                  </div>)
                }
              />
              { validDepartureAuto }
            </div>
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.DEPARTURE_TERMINAL }
              value={ segment.DepartureTerminal }
              label={ LABELS.TERMINAL }
              onChange={ (...props) => handleChangeInput(ticketInd, routeInd, index, ...props) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <div className={ styles.row }>
              <div className={ styles['col-1-2'] }>
                <label>{ LABELS.DEPARTURE_DATE }</label>
                <DatePicker
                  value={ segment.DepartureDate }
                  onChange={ ({ value }) =>
                    handleChangeInput(ticketInd, routeInd, index, null, FIELDS.DEPARTURE_DATE, value)
                  }
                  inputTheme={ COMPONENTS.DATEPICKER.INPUT_THEME.OPEN }
                  onBlur={ () => handleBlurInput(ticketInd, routeInd, index, FIELDS.DEPARTURE_DATE) }
                  className={ `componentHook ${validation.DepartureDate ? styles['no-valid'] : ''}` }
                />
                { validDepartureDate }
              </div>
              <div className={ styles['col-1-2'] }>
                <InputTime
                  field={ FIELDS.DEPARTURE_TIME }
                  value={ segment.DepartureTime }
                  label={ LABELS.DEPARTURE_TIME }
                  onChange={ value => handleChangeInput(ticketInd, routeInd, index, null, FIELDS.DEPARTURE_TIME, value) }
                />
                { validDepartureTime }
              </div>
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <div className={ `${styles['auto-input']} ${validation.ArrivalAirport.Code ? styles['no-valid'] : ''}` }>
              <label>{ LABELS.ARRIVAL_AIRPORT }</label>
              <Autocomplete
                menuStyle={ MENUSTYLE }
                value={ to.label }
                items={ to.suggests }
                getItemValue={ item => item.Code }
                onSelect={ (cValue, value) => this.handleSelectSuggest(FIELDS.TO, cValue, value) }
                onChange={ (event, value) => this.handleChangeSuggest(FIELDS.TO, value) }
                renderItem={ item =>
                  (<div className={ styles['autocomplete-item'] }>
                    { `${item.Code} — ${item.City} ${item.Country} ${item.Name}` }
                  </div>)
                }
                onBlur={ () => handleBlurInput(ticketInd, routeInd, index, FIELDS.ARRIVAL_AIRPORT_CODE) }
              />
              { validArrivalAuto }
            </div>
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.ARRIVAL_TERMINAL }
              value={ segment.ArrivalTerminal }
              label={ LABELS.TERMINAL }
              onChange={ (...props) => handleChangeInput(ticketInd, routeInd, index, ...props) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <div className={ styles.row }>
              <div className={ styles['col-1-2'] }>
                <label>{ LABELS.ARRIVAL_DATE }</label>
                <DatePicker
                  value={ segment.ArrivalDate }
                  onChange={ ({ value }) =>
                    handleChangeInput(ticketInd, routeInd, index, null, FIELDS.ARRIVAL_DATE, value)
                  }
                  inputTheme={ COMPONENTS.DATEPICKER.INPUT_THEME.OPEN }
                  onBlur={ () => handleBlurInput(ticketInd, routeInd, index, FIELDS.ARRIVAL_DATE) }
                  className={ `componentHook ${validation.ArrivalDate ? styles['no-valid'] : ''}` }
                />
                { validArrivalDate }
              </div>
              <div className={ styles['col-1-2'] }>
                <InputTime
                  field={ FIELDS.ARRIVAL_TIME }
                  value={ segment.ArrivalTime }
                  label={ LABELS.ARRIVAL_TIME }
                  onChange={ value => handleChangeInput(ticketInd, routeInd, index, null, FIELDS.ARRIVAL_TIME, value) }
                />
                { validArrivalTime }
              </div>
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.AIRCRAFT_NAME }
              value={ segment.AircraftName }
              label={ LABELS.AIRCRAFT_NAME }
              onChange={ (...props) => handleChangeInput(ticketInd, routeInd, index, ...props) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.BAGGAGE }
              value={ segment.Baggage }
              label={ LABELS.BAGGAGE }
              onChange={ (...props) => handleChangeInput(ticketInd, routeInd, index, ...props) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.CLASS }
              value={ segment.Class }
              label={ LABELS.CLASS }
              onChange={ (...props) => handleChangeInput(ticketInd, routeInd, index, ...props) }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Segments;
