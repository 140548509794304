import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

class AirTickets extends Component {
  static propTypes = {
    isEditing: PropTypes.bool,
    tickets: PropTypes.array.isRequired,
    employees: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.number,
    ]),
    providerNameOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    handlerDeletePart: PropTypes.func.isRequired,
    handlerRemoveEmployee: PropTypes.func.isRequired,
    onSelectEmployee: PropTypes.func.isRequired,
    handleChangeVatDetails: PropTypes.func.isRequired,
    handlerAddPart: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    validation: PropTypes.array.isRequired,
    airService: PropTypes.object.isRequired,
    isAwaitingTicketing: PropTypes.bool,
    specialBillAccount: PropTypes.object,
    tpDetails: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
    ]),
    onEmployeeFocus: PropTypes.func.isRequired,
    onChangeEmployee: PropTypes.func.isRequired,
    changeRate: PropTypes.object,
    penalties: PropTypes.object,
    surcharges: PropTypes.object,
    changeVat: PropTypes.object,
    returnFunds: PropTypes.object,
    onChangeFinancesEnabled: PropTypes.func,
    onChangeSurcharges: PropTypes.func,
    onChangeReturnFunds: PropTypes.func,
    airline: PropTypes.object,
    disablePickEployee: PropTypes.bool,
    handleGetInfo: PropTypes.func,
    waitingResponse: PropTypes.bool,
    handleChangeFlyClass: PropTypes.func.isRequired,
    getInfoError: PropTypes.string,
    handleSetInfoError: PropTypes.func,
    saveOperationAirline: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isEditing: false,
    isAwaitingTicketing: false,
    disablePickEployee: false,
    tpDetails: [],
    employees: null,
    changeRate: {},
    penalties: {},
    surcharges: {},
    changeVat: {},
    returnFunds: {},
    onChangeReturnFunds: () => {},
    onChangeFinancesEnabled: () => {},
    onChangeSurcharges: () => {},
    handleGetInfo: () => {},
    airline: {},
    waitingResponse: false,
    getInfoError: '',
    specialBillAccount: null,
    handleSetInfoError: () => {},
    saveOperationAirline: () => {},
  };

  handleChangeInput = (ticketInd, routeInd, segmentInd, event, field, value) => this.props.onChange({
    ticketInd,
    routeInd,
    segmentInd,
    field,
    value,
  });

  handleBlurInput = (...props) => this.props.onBlur(...props);

  render() {
    const {
      isEditing,
      tickets,
      employees,
      providerNameOptions,
      onSelectEmployee,
      onChangeEmployee,
      handleChangeVatDetails,
      handlerRemoveEmployee,
      handlerAddPart,
      handlerDeletePart,
      validation,
      airService,
      isAwaitingTicketing,
      tpDetails,
      onEmployeeFocus,
      changeRate,
      penalties,
      surcharges,
      changeVat,
      onChangeFinancesEnabled,
      onChangeSurcharges,
      airline,
      onChangeReturnFunds,
      returnFunds,
      disablePickEployee,
      handleGetInfo,
      waitingResponse,
      handleChangeFlyClass,
      getInfoError,
      handleSetInfoError,
      specialBillAccount,
      saveOperationAirline,
    } = this.props;

    const html = tickets.map((ticket, index) =>
      Item({
        isEditing,
        specialBillAccount,
        key: index,
        handleChangeInput: this.handleChangeInput,
        ticket,
        employees,
        providerNameOptions,
        onSelectEmployee,
        onChangeEmployee,
        handleChangeVatDetails,
        handlerRemoveEmployee,
        handlerAddPart,
        handlerDeletePart,
        validation: validation[index],
        handleBlurInput: this.handleBlurInput,
        ticketCount: tickets.length,
        airService,
        isAwaitingTicketing,
        tpDetails: tpDetails[index],
        onEmployeeFocus,
        changeRate,
        penalties,
        surcharges,
        changeVat,
        onChangeFinancesEnabled,
        onChangeSurcharges,
        airline,
        onChangeReturnFunds,
        returnFunds,
        disablePickEployee,
        handleGetInfo,
        waitingResponse,
        handleChangeFlyClass,
        getInfoError,
        handleSetInfoError,
        saveOperationAirline,
      })
    );

    return <div>{ html }</div>;
  }
}

export default AirTickets;

